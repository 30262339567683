import { createRouter, createWebHistory, type Router } from 'vue-router';
import Login from '../views/LoginPage.vue';
import Logout from '../views/LogoutPage.vue';
import Dashboard from '../views/DashboardPage.vue';
import CharterRoutes from '../views/CharterRoutes.vue';
import BusRoutes from '../views/BusRoutes.vue';
import PackagesIndex from '../views/Packages/PackagesIndex.vue';
import BuildPackage from '../views/Packages/BuildPackage.vue';
import PackageReport from '../views/Packages/PackageReport.vue';
import PackagesList from '../views/Packages/PackagesList.vue';
import Caching from '../views/Cache/CachingPage.vue';
import Wallet from '../views/WalletPage.vue';
import Bookings from '../views/BookingsPage.vue';
import AirlineIndex from '../views/AirlineRoutes/AirlineIndex.vue';
import AirlineRoutes from '../views/AirlineRoutes/AirlineRoutes.vue';
import AddAirlineRoute from '../views/AirlineRoutes/AddAirline.vue';
import PathNotFound from '../views/PathNotFound.vue';
import { useAuthStore } from '@/stores/auth';
import UCMIndex from '@/views/UCM/UCMIndex.vue';
import CachingIndex from '@/views/Cache/CachingIndex.vue';
import Hotel from '@/views/UCM/Hotel.vue';
import ListHotels from '@/views/UCM/HotelsList.vue';

import ExtraIndex from '@/views/Extra/ExtraIndex.vue';
import Extra from '@/views/Extra/Extra.vue';
import ListExtras from '@/views/Extra/ExtraList.vue';
import AllocationList from '@/views/Extra/AllocationList.vue';
import Allocation from '@/views/Extra/Allocation.vue';
import Jobs from '@/views/Cache/Jobs.vue';

const router: Router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login/:jwt',
            name: 'Login',
            component: Login,
        },
        {
            path: '/logout',
            name: 'Logout',
            component: Logout,
        },
        {
            path: '/',
            name: 'Dashboard',
            component: Dashboard,
        },
        {
            path: '/charterRoutes',
            name: 'Charter Routes',
            component: CharterRoutes,
        },
        {
            path: '/busRoutes',
            name: 'Bus Routes',
            component: BusRoutes,
        },
        {
            path: '/airlineRoutes',
            name: 'Airline Routes',
            component: AirlineIndex,
            children: [
                { path: '', name: 'AirlineRoutesOverview', component: AirlineRoutes },
                {
                    path: 'addAirline',
                    name: 'Add New Airline Route',
                    component: AddAirlineRoute,
                },
            ],
        },
        {
            path: '/packages',
            name: 'Packages',
            component: PackagesIndex,
            children: [
                { path: '', name: 'Packages List', component: PackagesList },
                {
                    path: 'buildPackage/:id?/:isGroup?',
                    name: 'Build New Packages',
                    component: BuildPackage,
                },
                {
                    path: 'packageReport/:id?/:isGroup?',
                    name: 'Package Report',
                    component: PackageReport,
                },
            ],
        },
        {
            path: '/caching',
            name: 'Caching',
            component: CachingIndex,
            children: [
                {
                    path: '', // Default child route for "/caching"
                    name: 'Cache',
                    component: Caching, // Default view
                },
                { 
                    path: 'jobs/:id?', // Nested route for jobs
                    name: 'Jobs', 
                    component: Jobs, 
                },
            ],
        },
        {
            path: '/wallet',
            name: 'Wallet',
            component: Wallet,
        },
        {
            path: '/bookings',
            name: 'Bookings',
            component: Bookings,
        },
        {
            path: '/ucm',
            name: 'UCM',
            component: UCMIndex,
            children: [
                { 
                    path: 'hotels', 
                    name: 'Unified Hotel Data Integration System', 
                    component: ListHotels, 
                },
                {
                    path: 'hotel',
                    name: 'Hotel',
                    component: Hotel,
                },
            ],
        },
        {
            path: '/extra',
            name: 'Extra',
            component: ExtraIndex,
            children: [
                { 
                    path: 'list', 
                    name: 'Extras List', 
                    component: ListExtras, 
                },
                {
                    path: ':id?',
                    name: 'Extra Add',
                    component: Extra,
                },
                { 
                    path: '/allocation/list', 
                    name: 'Allocations List', 
                    component: AllocationList, 
                },
                {
                    path: '/allocation/:id?',
                    name: 'Allocation Add',
                    component: Allocation,
                },
            ],
        },
        { path: '/:pathMatch(.*)*', name: '404', component: PathNotFound },
    ],
});

// router.beforeEach((to, next) => {
//     const auth = useAuthStore();
//     if (to.name === 'Login') {
//         next;
//     } else if (!auth.isAuthenticated && to.name != 'Logout') {
//         router.replace({ path: '/logout' });
//     }

// });

router.beforeEach((to, from, next) => {
    const auth = useAuthStore();
    if (to.name === 'Login') {
        next(); 
    } else if (!auth.isAuthenticated && to.name !== 'Logout') {
        next('/logout'); 
    } else {
        next(); 
    }
});

export default router;
