<script setup lang="ts">
import { nextTick, onMounted, onBeforeUnmount, ref, toRaw, watch, type Ref } from 'vue';
import axiosApiInstance from '@/helpers/api.axios.headers';
import type { Accordion, AccordionItem } from '@/types/accordion.interfaces';

interface JobInfo {
  id: number;
  root_id: number;
  job_count: number;
  progress: string;
  status: string;
  offers: string;
  errors: number;
  job_avg_duration: number;
  nr_offers	: number;
  cache_size: number;
  unique_hotels: number;
  hotels_per_providers: number;
  dump_files: number;
  dump_file_size: number;
  cache_identifier: number;
  added_at: Date;
  modified_at: Date;
}

const items: Ref<Array<JobInfo>> = ref([]);
const currentPage = ref(1);
const totalPages = ref(0);
const isLoading = ref(false);

onMounted(() => {
    getCachePrimaryDetails();
    window.addEventListener('scroll', handleScroll); 
});

onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll); // Clean up event listener
});


const getCachePrimaryDetails = () => {
    if (isLoading.value || (totalPages.value && currentPage.value > totalPages.value)) {
        return;
    }

    isLoading.value = true;
    axiosApiInstance
        .get('/getCachePrimaryDetails', {
            params: {
                page: currentPage.value,
            },
        })
        .then(function (response) {
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                items.value = [...items.value, ...response.data.cache]; 
                totalPages.value = response.data.pagination.totalPages; 
                currentPage.value += 1; 
            }
        })
        .catch(function (error) {
            alert(error);
        })
        .finally(() => {
            isLoading.value = false;
        });
};

const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
        getCachePrimaryDetails();
    }
};

const timePassed = (addedAt: string): string => {

    const diffInMs = new Date().getTime() - new Date(addedAt).getTime();
    return `${Math.floor(diffInMs / (1000 * 60 * 60 * 24))} days, ${Math.floor(diffInMs / (1000 * 60 * 60)) % 24} hours, ${Math.floor(diffInMs / (1000 * 60)) % 60} minutes`;
};

const formattedRoDate = (timestamp: string): string => 
    new Date(timestamp).toLocaleString('ro-RO', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' }).replace(',', '').replace(/\./g, '-');



watch(items, (newItems) => {
    nextTick(() => {
        const accordionItems = [];

        for (let index = 0; index < toRaw(newItems).length; index++) {
            
            accordionItems.push({
                id: `accordion-flush-heading-${index}`,
                triggerEl: document.querySelector(`#accordion-flush-heading-${index}`),
                targetEl: document.querySelector(`#accordion-flush-body-${index}`),
                active: false,
            });
        }

        const options = {};

        // @ts-ignore: Unreachable code error
        new Accordion(accordionItems, options);
    });
});

</script>
<template>
    <div>
        <ol 
            id="accordion-flush" data-accordion="collapse"
            data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
            data-inactive-classes="text-gray-500 dark:text-gray-400"
            class="relative border-s border-indigo-400 dark:border-gray-700">                  
            <li class="mb-4 ms-2 bg-gray-100 rounded p-4" v-for="(item, index) in items" :key="index">            
                <span class="absolute flex items-center justify-center w-6 h-6 bg-blue-100 rounded-full -start-3 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                   
                    <svg v-if="item.status === 'wip'" class="w-3.5 h-3.5 text-blue-800 dark:text-blue-300" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1C6.44772 1 6 1.44772 6 2V3H5C3.34315 3 2 4.34315 2 6V20C2 21.6569 3.34315 23 5 23H13.101C12.5151 22.4259 12.0297 21.7496 11.6736 21H5C4.44772 21 4 20.5523 4 20V11H20V11.2899C20.7224 11.5049 21.396 11.8334 22 12.2547V6C22 4.34315 20.6569 3 19 3H18V2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2V3H8V2C8 1.44772 7.55228 1 7 1ZM16 6V5H8V6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6V5H5C4.44772 5 4 5.44772 4 6V9H20V6C20 5.44772 19.5523 5 19 5H18V6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6Z" fill="#0F0F0F"></path> <path d="M17 16C17 15.4477 17.4477 15 18 15C18.5523 15 19 15.4477 19 16V17.703L19.8801 18.583C20.2706 18.9736 20.2706 19.6067 19.8801 19.9973C19.4896 20.3878 18.8564 20.3878 18.4659 19.9973L17.2929 18.8243C17.0828 18.6142 16.9857 18.3338 17.0017 18.0588C17.0006 18.0393 17 18.0197 17 18V16Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18ZM13.9819 18C13.9819 20.2191 15.7809 22.0181 18 22.0181C20.2191 22.0181 22.0181 20.2191 22.0181 18C22.0181 15.7809 20.2191 13.9819 18 13.9819C15.7809 13.9819 13.9819 15.7809 13.9819 18Z" fill="#0F0F0F"></path> </g></svg>

                    <svg v-else-if="item.status === 'finished'" class="w-3.5 h-3.5 text-blue-800 dark:text-blue-300" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1C6.44772 1 6 1.44772 6 2V3H5C3.34315 3 2 4.34315 2 6V20C2 21.6569 3.34315 23 5 23H13.101C12.5151 22.4259 12.0297 21.7496 11.6736 21H5C4.44772 21 4 20.5523 4 20V11H20V11.2899C20.7224 11.5049 21.396 11.8334 22 12.2547V6C22 4.34315 20.6569 3 19 3H18V2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2V3H8V2C8 1.44772 7.55228 1 7 1ZM16 6V5H8V6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6V5H5C4.44772 5 4 5.44772 4 6V9H20V6C20 5.44772 19.5523 5 19 5H18V6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6Z" fill="#0F0F0F"></path> <path d="M15.2929 17.7071C15.6834 17.3166 16.3166 17.3166 16.7071 17.7071L17.3483 18.3482L19.2473 16.4491C19.6379 16.0586 20.271 16.0586 20.6616 16.4491C21.0521 16.8397 21.0521 17.4728 20.6616 17.8634L18.1213 20.4036C18.0349 20.49 17.9367 20.5573 17.8318 20.6054C17.4488 20.8294 16.9488 20.7772 16.6203 20.4487L15.2929 19.1213C14.9024 18.7308 14.9024 18.0976 15.2929 17.7071Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18ZM13.9819 18C13.9819 20.2191 15.7809 22.0181 18 22.0181C20.2191 22.0181 22.0181 20.2191 22.0181 18C22.0181 15.7809 20.2191 13.9819 18 13.9819C15.7809 13.9819 13.9819 15.7809 13.9819 18Z" fill="#0F0F0F"></path> </g></svg>

                    <svg v-else-if="item.status === 'new'" class="w-3.5 h-3.5 text-blue-800 dark:text-blue-300" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M7 1C6.44772 1 6 1.44772 6 2V3H5C3.34315 3 2 4.34315 2 6V20C2 21.6569 3.34315 23 5 23H13.101C12.5151 22.4259 12.0297 21.7496 11.6736 21H5C4.44772 21 4 20.5523 4 20V11H20V11.2899C20.7224 11.5049 21.396 11.8334 22 12.2547V6C22 4.34315 20.6569 3 19 3H18V2C18 1.44772 17.5523 1 17 1C16.4477 1 16 1.44772 16 2V3H8V2C8 1.44772 7.55228 1 7 1ZM16 6V5H8V6C8 6.55228 7.55228 7 7 7C6.44772 7 6 6.55228 6 6V5H5C4.44772 5 4 5.44772 4 6V9H20V6C20 5.44772 19.5523 5 19 5H18V6C18 6.55228 17.5523 7 17 7C16.4477 7 16 6.55228 16 6Z" fill="#0F0F0F"></path> <path d="M17 16C17 15.4477 17.4477 15 18 15C18.5523 15 19 15.4477 19 16V17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H19V20C19 20.5523 18.5523 21 18 21C17.4477 21 17 20.5523 17 20V19H16C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17H17V16Z" fill="#0F0F0F"></path> <path fill-rule="evenodd" clip-rule="evenodd" d="M24 18C24 21.3137 21.3137 24 18 24C14.6863 24 12 21.3137 12 18C12 14.6863 14.6863 12 18 12C21.3137 12 24 14.6863 24 18ZM13.9819 18C13.9819 20.2191 15.7809 22.0181 18 22.0181C20.2191 22.0181 22.0181 20.2191 22.0181 18C22.0181 15.7809 20.2191 13.9819 18 13.9819C15.7809 13.9819 13.9819 15.7809 13.9819 18Z" fill="#0F0F0F"></path> </g></svg>

                    <svg v-else viewBox="0 0 24 24" class="w-3.5 h-3.5 text-blue-800 dark:text-blue-300" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M6 2C6 1.44772 6.44772 1 7 1C7.55228 1 8 1.44772 8 2V3H16V2C16 1.44772 16.4477 1 17 1C17.5523 1 18 1.44772 18 2V3H19C20.6569 3 22 4.34315 22 6V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V6C2 4.34315 3.34315 3 5 3H6V2ZM16 5V6C16 6.55228 16.4477 7 17 7C17.5523 7 18 6.55228 18 6V5H19C19.5523 5 20 5.44772 20 6V9H4V6C4 5.44772 4.44772 5 5 5H6V6C6 6.55228 6.44772 7 7 7C7.55228 7 8 6.55228 8 6V5H16ZM4 11V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V11H4Z" fill="#0F0F0F"></path> </g></svg>
                </span>
                <h3 :id="'accordion-flush-heading-'+index">
                    <div class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white w-full">
                        <button type="button" class="flex items-center justify-between p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 gap-3" :data-accordion-target="'#accordion-body-'+index" aria-expanded="true" >
                            <svg data-accordion-icon class="w-3 h-3 rotate-180 shrink-0" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
                            </svg>
                        </button>
                        {{ formattedRoDate( String(item.added_at) ) }} - {{ item.status }} 
                        <span v-if="index === 0" class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300 ms-3">
                            Last run - {{ timePassed( String(item.added_at ) ) }}
                        </span>
                    </div>
                </h3>
                <div :id="'accordion-flush-body-'+index" :class="index !== 0 ? 'hidden' : ''">
                    <div class="border-l-4 fw-bold border-cyan-500 ps-2 mb-3 dark:text-gray-200">
                        <p class="text-gray-500 dark:text-gray-400">General Information |
                            <router-link 
                                :to="`/caching/jobs/${item.cache_identifier	}`" 
                                class="inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                View jobs →
                            </router-link>
                        </p>
                    </div>  
                    <hr class="mb-2 border-cyan-500">
                    <div class="border-l-4 ps-2 border-cyan-500">
                        <div class="gap-6 sm:grid sm:grid-cols-9 dark:text-gray-200">
                            <div>
                                <dl>
                                    <dt class="text-sm font-medium text-gray-500 dark:text-gray-400">
                                        Progress
                                        <span class="text-sm w-12 font-medium text-gray-500 dark:text-gray-400">{{ item.progress }} %</span>
                                    </dt>
                                    <dd class="flex items-center mb-3">
                                        <div class="w-full bg-gray-200 rounded h-2.5 dark:bg-gray-700 me-2">
                                            <div class="bg-blue-600 h-2.5 rounded dark:bg-blue-500" :style="'width: '+item.progress+'%'"></div>
                                        </div>
                                    </dd>
                                </dl>
                            </div>
                            <div>
                                Nr of jobs:
                                <br>
                                <span class="flex items-center text-sm font-medium text-gray-900 dark:text-white me-3">
                                    <span class="flex w-2.5 h-2.5 bg-blue-600 rounded-full me-1.5 flex-shrink-0"></span>
                                    {{ item.job_count }}
                                </span>
                            </div>
                            <div>
                                Errors: 
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{{ item.errors }}</span>
                            </div>
                            <div>
                                Offers: 
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{{ item.nr_offers }}</span>
                            </div>
                            <div>
                                Avrage duration: 
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
                                    {{ (item.job_avg_duration / 60).toFixed(2) }} min
                                </span>
                            </div>
                            <div>
                                Unique hotels: 
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{{ item.unique_hotels }}</span>
                            </div>
                            <div>
                                Total hotels:
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{{ item.hotels_per_providers }}</span>
                            </div>
                            <div>
                                Dump files/size:
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{{ item.dump_files }}/{{ item.dump_file_size }} Mb</span>
                            </div>
                            <div>
                                Total cache size:
                                <br>
                                <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">{{ item.cache_size }} Gb</span>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ol>
        <p v-if="isLoading">
            <div class="flex items-center my-3 justify-center border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                <div class="px-6 py-2 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">Loading...</div>
            </div>
        </p>
        <p v-if="!isLoading && currentPage > totalPages">No more items to load.</p>
    </div>
</template>