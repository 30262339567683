import { defineStore } from 'pinia';
import type { AllocationInterface } from '@/types/extra/allocation.interface';


export const useAllocation = defineStore('allocation', {
    state: (): AllocationInterface => ({
        package_extra_service_ids: "",
        outbound: "",
        inbound: "",
        stay: "",
        hotel: "",
        from_date: "",
        to_date: "",
        date_intervals: [
            {
                from_date: '',
                to_date:''
            }
        ]
    }),

    getters: {
        outboundLocations: (state) => state.outbound.split(','),
        inboundLocations: (state) => state.inbound.split(',')
    },

    actions: {
        setOubtound(locations: string[]) {
            
            this.outbound = String(locations);
        },
        setInbound(locations: string[]) {
            this.inbound = String(locations);
        },
        setDate(date: string[]) {
            this.from_date = date[0];
            this.to_date = date[1];
        },
        setStay(array: string[]) {
            this.stay = array.join();
        },
        setHotels(array: string[]) {
            this.hotel = array.join();
        },
        setExtra(array: string[]) {
            this.package_extra_service_ids = array.join();
            
        },
        resetAllocation(){
            this.package_extra_service_ids = "",
            this.outbound = "",
            this.inbound = "",
            this.stay = "",
            this.hotel = "",
            this.from_date = "",
            this.to_date = "",
            this.date_intervals = [
                {
                    from_date: '',
                    to_date:''
                }
            ]
        }
    }
});
