<script setup lang="ts">
import IconPackage from '@/components/icons/IconPackage.vue';
import PieChart from '../assets/charts/PieChart';
import axiosApiInstance from '@/helpers/api.axios.headers';
import { type Ref, ref, toRaw } from 'vue';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';
import type { DashboardObject, CartData } from '@/types/dashboard.interface';
import IconMapLocation from '../components/icons/IconMapLocation.vue';
import IconBed from '../components/icons/IconBed.vue';

const fullscreenLoader = ref(false);
const dashboardObject: Ref<DashboardObject> = ref({
    packages: 0,
    destinations: 0,
    selectedHotels: 0,
    destinations4Dasboard: [
        {
            locationName: '',
            nrPackages: 0,
            transportTypes: '',
        },
    ],
    report: {
    }
});

axiosApiInstance
    .get('/getDashboardInformation')
    .then(function (response) {
        fullscreenLoader.value = true;
        if (!response.data.status) {
            alert(response.data.message);
        } else {
            dashboardObject.value = response.data;

        }
    })
    .catch(function (error) {
        alert(error);
    });
</script>
<template>
    <div class="p-10">
        <div class="flex justify-between mt-8">
            <div class="w-1/3 mx-3">
                <div
                    class="p-5 bg-white border rounded flex dark:bg-gray-900 dark:text-white dark:border-gray-500"
                >
                    <div class="bg-indigo-600 p-2 w-12 rounded flex">
                        <IconPackage class="fill-white" />
                    </div>
                    <div class="ml-4">
                        <div class="text-slate-500 dark:text-white">Packages</div>
                        <div class="flex">
                            <div class="text-xl font-bold">
                                {{ dashboardObject.packages }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/3 mx-3">
                <div
                    class="p-5 bg-white border rounded flex dark:bg-gray-900 dark:text-white dark:border-gray-500"
                >
                    <div class="bg-indigo-600 p-2 w-12 rounded flex">
                        <IconMapLocation class="fill-white" />
                    </div>
                    <div class="ml-4">
                        <div class="text-slate-500 dark:text-white">Destinations</div>
                        <div class="flex">
                            <div class="text-xl font-bold">
                                {{ dashboardObject.destinations }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-1/3 mx-3">
                <div
                    class="p-5 bg-white border rounded flex dark:bg-gray-900 dark:text-white dark:border-gray-500"
                >
                    <div class="bg-indigo-600 p-2 w-12 rounded flex">
                        <IconBed class="fill-white" />
                    </div>
                    <div class="ml-4">
                        <div class="text-slate-500 dark:text-white">Hotels</div>
                        <div class="flex">
                            <div class="text-xl font-bold">
                                {{ dashboardObject.selectedHotels }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div class="flex justify-between mt-8 hidden">
            <div class="w-1/2 mx-3">
                <div class="overflow-x-auto relative">
                    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <thead
                            class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                        >
                            <tr>
                                <th scope="col" class="py-3 px-6">Destination</th>
                                <th scope="col" class="py-3 px-6">Nr. Packages</th>
                                <th scope="col" class="py-3 px-6">Transport Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in dashboardObject.destinations4Dasboard"
                                :key="index"
                                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                                <th
                                    scope="row"
                                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    {{ item.locationName }}
                                </th>
                                <td class="py-4 px-6">
                                    {{ item.nrPackages }}
                                </td>
                                <td class="py-4 px-6">
                                    {{
                                        item.transportTypes == 'bus_charter'
                                            ? 'Bus Charter'
                                            : 'Flight Charter'
                                    }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <!-- <br>
    <div v-if="dashboardObject.report" class="p-6 bg-gray-100 rounded-lg shadow-md bg-white">
        <h2 class="text-2xl font-bold text-gray-800 mb-3">Caching Report</h2>


        <div role="status" class="max-w-sm animate-pulse"  v-show="!fullscreenLoader">
            <p>
                Fething report, please wait...
            </p>
            <br>
            <div class="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
            <div class="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
            <span class="sr-only">Loading...</span>
        </div>


        <div v-if="dashboardObject.report.last_24h" class="mb-8">
            <div class="text-xl font-semibold text-blue-600 underline underline-offset-2 mb-4">
                Last 24 Hours
            </div>
            <div class="grid grid-cols-2 gap-4">
                <div class="p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Total Jobs:</span>
                    <span class="text-gray-700">{{ dashboardObject.report.last_24h.total_jobs }}</span>
                </div>
                <div class="p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Average Processing Time: </span>
                    <span class="text-gray-700">{{ dashboardObject.report.last_24h.avg_processing_time }}</span>
                </div>
                <div class="col-span-2 p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Status Count:</span>
                    <ul class="list-disc pl-6 text-gray-700">
                        <li>New: {{ dashboardObject.report.last_24h.status_count.new }}</li>
                        <li>In progress: {{ dashboardObject.report.last_24h.status_count.wip }}</li>
                        <li>Finished: {{ dashboardObject.report.last_24h.status_count.finished }}</li>
                    </ul>
                </div>
                <div class="col-span-2 p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Destinations:</span>
                    <ul class="list-disc pl-6 text-gray-700">
                        <li v-for="(count, country) in dashboardObject.report.last_24h.destinations" :key="country">
                            {{ country }}: {{ count }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div v-if="dashboardObject.report.all_time">
            <div class="text-xl font-semibold text-green-600 underline underline-offset-2 mb-4">
                All Time
            </div>
            <div class="grid grid-cols-2 gap-4">
                <div class="p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Total Jobs:</span>
                    <span class="text-gray-700">{{ dashboardObject.report.all_time.total_jobs }}</span>
                </div>
                <div class="p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Average Processing Time: </span>
                    <span class="text-gray-700">{{ dashboardObject.report.all_time.avg_processing_time }}</span>
                </div>
                <div class="col-span-2 p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Status Count:</span>
                    <ul class="list-disc pl-6 text-gray-700">
                        <li>New: {{ dashboardObject.report.all_time.status_count.new }}</li>
                        <li>In progress: {{ dashboardObject.report.all_time.status_count.wip }}</li>
                        <li>Finished: {{ dashboardObject.report.all_time.status_count.finished }}</li>
                    </ul>
                </div>
                <div class="col-span-2 p-4 bg-white rounded-lg shadow">
                    <span class="font-semibold">Destinations:</span>
                    <ul class="list-disc pl-6 text-gray-700">
                        <li v-for="(count, country) in dashboardObject.report.all_time.destinations" :key="country">
                            {{ country }}: {{ count }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
    
</template>
