<script setup lang="ts">
import axiosApiInstance from '@/helpers/api.axios.headers';
import AutosuggestRoute from '@/components/extra/AutosuggestInput.vue';
import AutosuggestHotel from '@/components/extra/AutosuggestHotel.vue';
import AutosuggestExtra from '@/components/extra/AutosuggestExtra.vue';
import { ref, watch, toRaw, type Ref, onMounted } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import { useAllocation } from '@/stores/allocationExtra';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import IconTrashBin from '@/components/icons/IconTrashBin.vue';
import { format, parse } from 'date-fns';
import type { DateInteval, SimpleExtra, SimpleHotel } from '@/types/extra/allocation.interface';



const fullscreenLoader = ref(false);

const allocationStore = useAllocation();
const stayArray: Ref<string[]> = ref([]);

const route = useRoute();
const allocationId: Ref<string> = ref(String(route.params.id));
const isEdit: Ref<boolean> = ref(false);

const editHotels = ref();
const editExtra = ref();

const editDateInterval = ref({
                from_date: '',
                to_date: '',
            });


onMounted(() => {
    
    if(allocationId.value != ''){
        isEdit.value = true;
    }
    if (isEdit.value) {

        fullscreenLoader.value = true;
    axiosApiInstance.get(`/extra_service_allocation_rules/get/${allocationId.value}`, {})
    .then(function (response) {
        if (!response.data.status) {
            alert(response.data.message);
        } else {

            const allocationToEdit = response.data.allocation_rules[0];
            allocationStore.package_extra_service_ids = allocationToEdit.package_extra_service_ids;
            allocationStore.outbound = allocationToEdit.outbound;
            allocationStore.inbound = allocationToEdit.inbound;
            allocationStore.stay = allocationToEdit.stay;
            allocationStore.from_date = (allocationToEdit.from_date.split(' ')[0]).split("-").reverse().join("-");
            allocationStore.to_date = (allocationToEdit.to_date.split(' ')[0]).split("-").reverse().join("-");

            editHotels.value = allocationToEdit.extra_data.hotels;
            editExtra.value = allocationToEdit.extra_data.services;

            allocationStore.setExtra(
                allocationToEdit.extra_data.services.map((service: SimpleExtra) => service.id)
            )
            allocationStore.setHotels(
                allocationToEdit.extra_data.hotels.map((hotel: SimpleHotel) => hotel.hotel_id)
            )

            // editDateInterval.value = {
            //     from_date: (allocationToEdit.from_date.split(' ')[0]).split("-").reverse().join("-"),
            //     to_date:(allocationToEdit.to_date.split(' ')[0]).split("-").reverse().join("-"),
            // };
            editDateInterval.value = {
                from_date: formatDate( new Date( allocationToEdit.from_date.split(' ')[0] ) ),
                to_date: formatDate( new Date( allocationToEdit.to_date.split(' ')[0] ) ),
            };

            stayArray.value = allocationToEdit.stay.split(',');

        }
        fullscreenLoader.value = false;
    })
    .catch(function (error) {
        alert(error);
    });
    }else{
        useAllocation().resetAllocation();
    }

})

const addDateInterval = () => {
    allocationStore.date_intervals.push({
        from_date: formatDate(new Date()), 
        to_date: formatDate(new Date())
        
    })
}

const deleteDateInterval = (index: number) => {
    allocationStore.date_intervals.splice(index, 1);
}

const changeDate = (event: [Date, Date], index: number): void => {
    const [fromDate, toDate] = event;

    allocationStore.date_intervals[index] = {
        from_date: formatDate(fromDate),
        to_date: formatDate(toDate),
    };
};
const changeEditDate = (event: [Date, Date]): void => {

    const [fromDate, toDate] = event;

    allocationStore.from_date = formatDate(fromDate);
    allocationStore.to_date = formatDate(toDate);

    editDateInterval.value = {
        from_date: allocationStore.from_date,
        to_date: allocationStore.to_date
    };
};

const getDateInterval = (interval: DateInteval): [Date, Date] => {

    return [parseDate( String(interval.from_date) ), parseDate( String(interval.to_date) )];
};

const toggleStay = (e: Event) => {
    
    const target = e.target as HTMLInputElement;

    const value = target.value;

    const staySet = new Set(toRaw(stayArray.value));

    if (target.checked) {
        staySet.add(value);
    } else {
        staySet.delete(value);
    }

    // console.log(stayArray.value);
    

    // Update stayArray with the updated Set
    stayArray.value = Array.from(staySet);

    allocationStore.setStay(toRaw(stayArray.value));
    
}
// const toggleAllStays = (e: Event) => {
    
//     const target = e.target as HTMLInputElement;
//     const isChecked = target.checked;

//     let checkList = document.querySelectorAll('[data-type="check-stay"]');
//     checkList.forEach( (check: any) => {
//         check.checked = isChecked;
//     });

//     if(isChecked === true){
//         allocationStore.setStay(
//             ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21']

//         )

//     }else{
//         allocationStore.setStay(
//             []
//         )
//     }
    
// }

const addAllocation = () => {
    
    fullscreenLoader.value = true;
    const dataToSend = {
        package_extra_service_ids: allocationStore.package_extra_service_ids,
        outbound: allocationStore.outbound,
        inbound: allocationStore.inbound,
        stay:  (stayArray.value).toString(),
        hotel: allocationStore.hotel,
        date_intervals: allocationStore.date_intervals
    }

    // if(allocationStore.hotel == ''){
    //     delete dataToSend.hotel;
    // }

    axiosApiInstance
        .post('/extra_service_allocation_rules/create', {
            ...dataToSend,
        })
        .then(function (response) {
            fullscreenLoader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
                router.push('/allocation/list');
            }
        })
        .catch(function (error) {
            alert(error);
        });

}
const editAllocation = () => {
    
    fullscreenLoader.value = true;
    const dataToSend = {
        package_extra_service_ids: allocationStore.package_extra_service_ids,
        outbound: allocationStore.outbound,
        inbound: allocationStore.inbound,
        stay:  (stayArray.value).toString(),
        hotel: allocationStore.hotel,
        from_date:  allocationStore.from_date,
        to_date: allocationStore.to_date,
    }

    axiosApiInstance
        .post(`/extra_service_allocation_rules/update/${allocationId.value}`, {
            ...dataToSend,
        })
        .then(function (response) {
            fullscreenLoader.value = false;
            if (!response.data.status) {
                alert(response.data.message);
            } else {
                alert(response.data.message);
                router.push('/allocation/list');
            }
        })
        .catch(function (error) {
            alert(error);
        });
}

const formatDate = (date: Date): string => {
    return format(date, 'dd-MM-yyyy'); // Adjust the format as needed
};
const parseDate = (dateStr: string): Date => {
  return parse(dateStr, 'dd-MM-yyyy', new Date());
};




</script>
<template>
    <FullscreenLoader v-show="fullscreenLoader" />
    <div class="p-4 bg-gray-50 rounded-lg dark:bg-gray-800">
        <div class="container p-4 bg-gray-50 rounded-lg dark:bg-gray-800 mb-8">
            <div>
                <div class="text-xl underline underline-offset-2">
                    Route
                </div>
                <div class="p-4">
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-2">
                        <label class="block text-gray-700">
                            &#8544;. Outbound
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Search airports or countries for outbound flight</p>
                        </div>
                        <AutosuggestRoute :type="'outbound'" :editData="allocationStore.outbound" :key="allocationStore.outbound"/>
                    </div>
                </div>
                <div class="p-4">
                    <div class="col-span-6 sm:col-span-3 mr-2 mb-2">
                        <label class="block text-gray-700">
                            &#8545;. Inbound
                        </label>
                        <div class="text-sm">
                            <p class="text-gray-500">Search airports or countries for outbound flight</p>
                        </div>
                        <AutosuggestRoute :type="'inbound'" :editData="allocationStore.inbound" :key="allocationStore.inbound"/>
                    </div>
                </div>
            </div>
            <div class="max-w-sm">
                <div class="text-xl underline underline-offset-2">
                    Interval
                </div>
                <div class="p-4">
                    <div v-if="isEdit">
                        <Datepicker 
                                range 
                                :enableTimePicker="false" 
                                :clearable="false" 
                                multi-calendars
                                :modelValue="getDateInterval(editDateInterval)"
                                @update:modelValue="changeEditDate($event)"
                            />
                    </div>
                    <div v-else>
                        <button class="btn-primary" @click="addDateInterval">
                            Add new Interval
                        </button>
                        <div v-for="(interval, index) in allocationStore.date_intervals" :key="index"
                            class="flex items-center">
                            <div class="me-2">
                                {{ index + 1 }}
                            </div>
                            <Datepicker 
                                range 
                                :enableTimePicker="false" 
                                :modelValue="getDateInterval(interval)"
                                @update:modelValue="changeDate($event, index)"
                                :clearable="false" 
                                multi-calendars
                            />
                            <div v-if="allocationStore.date_intervals.length > 1" class="me-2">
                                <button type="button" title="Delete this interval"
                                    class="flex items-center py-1 px-2 mx-2 text-xs font-medium text-center text-white bg-red-700 rounded hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                    @click="deleteDateInterval(index)">
                                    <IconTrashBin />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="text-xl underline underline-offset-2">
                    Stay Length
                </div>
                <div class="text-sm">
                    <p class="text-gray-500">Apply allocation for certain stay lenghts(nights)</p>
                </div>
                <div class="p-4">
                    <ul
                        class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        <li v-for="n in 21"
                            class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                            <div class="flex items-center ps-2">
                                <input 
                                    :id="'vue-checkbox-list_' + n" 
                                    :value="n"
                                    type="checkbox"
                                    v-model="stayArray"
                                    data-type="check-stay"
                                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500">
                                <label 
                                    :for="'vue-checkbox-list_' + n"
                                    class="w-full py-2 ms-1 text-sm font-medium text-gray-900 dark:text-gray-300">
                                    {{ n }}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <div class="text-xl underline underline-offset-2">
                    Hotels
                </div>
                <div class="col-span-6 sm:col-span-3 mr-2 mb-2">
                    <label class="block text-gray-700">
                        Search Giata Hotels
                    </label>
                    <AutosuggestHotel :editData="editHotels" :key="editHotels"/>
                </div>
            </div>
            <div>
                <div class="text-xl underline underline-offset-2">
                    Extras
                </div>
                <div class="col-span-6 sm:col-span-3 mr-2 mb-2">
                    <label class="block text-gray-700">
                        Search extra_services that you created to add
                    </label>
                    <AutosuggestExtra  :editData="editExtra" :key="editExtra"/>
                </div>
            </div>
            <div v-if="isEdit">
                <button type="button" class="btn-success mt-1" @click="editAllocation()">
                    Edit Allocation
                </button>
            </div>
            <div v-else>
                <button type="button" class="btn-success mt-1" @click="addAllocation">
                    Add Allocation
                </button>
            </div>
        </div>
    </div>
</template>