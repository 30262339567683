<script setup lang="ts">
import { onMounted } from 'vue';
import Report from '@/components/cache/Report.vue'

onMounted(() => {
    const tabElements = [

        {
            id: 'analitics',
            triggerEl: document.querySelector('#analitics-tab-example'),
            targetEl: document.querySelector('#analitics-example'),
        },
        {
            id: 'profile',
            triggerEl: document.querySelector('#profile-tab-example'),
            targetEl: document.querySelector('#profile-example'),
        },
        {
            id: 'status',
            triggerEl: document.querySelector('#status-tab-example'),
            targetEl: document.querySelector('#status-example'),
        },
    ];

    // options with default values
    const options = {
        defaultTabId: 'analitics',
        activeClasses:
            'text-blue-600 hover:text-blue-600 dark:text-blue-500 dark:hover:text-blue-400 border-blue-600 dark:border-blue-500',
        inactiveClasses:
            'text-gray-500 hover:text-gray-600 dark:text-gray-400 border-gray-100 hover:border-gray-300 dark:border-gray-700 dark:hover:text-gray-300',
        onShow: () => {
            // console.log('tab is shown');
        },
    };

    // @ts-ignore: Unreachable code error
    const tabs = new Tabs(tabElements, options);
});
</script>
<template>
    <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400"
            id="tabExample"
            role="tablist"
        >
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    id="analitics-tab-example"
                    type="button"
                    role="tab"
                    aria-controls="analitics-example"
                    aria-selected="false"
                >
                    Analitics
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    id="profile-tab-example"
                    type="button"
                    role="tab"
                    aria-controls="profile-example"
                    aria-selected="false"
                >
                    Profile
                </button>
            </li>
            <li class="mr-2" role="presentation">
                <button
                    class="inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    id="status-tab-example"
                    type="button"
                    role="tab"
                    aria-controls="status-example"
                    aria-selected="false"
                >
                    Status
                </button>
            </li>
        </ul>
    </div>
    <div id="tabContentExample">
        <div
            class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="analitics-example"
            role="tabpanel"
            aria-labelledby="analitics-tab-example"
        >
            <div>
                <Report />
            </div>
        </div>
        <div
            class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="profile-example"
            role="tabpanel"
            aria-labelledby="profile-tab-example"
        >
            <div>
                <div>
                    4. Cache Invalidation:

                    Decide on a cache expiration strategy. You can set a time-based expiration for cache entries, or you can implement cache invalidation logic triggered by changes in the underlying data.
                </div>
            </div>
        </div>
        <div
            class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="status-example"
            role="tabpanel"
            aria-labelledby="status-tab-example"
        >
            <p class="text-sm text-gray-500 dark:text-gray-400">
                This is some placeholder content the
                <strong class="font-medium text-gray-800 dark:text-white"
                    >status tab's associated content</strong
                >. Clicking another tab will toggle the visibility of this one for the next. The tab
                JavaScript swaps classes to control the content visibility and styling.
            </p>
        </div>
    </div>
</template>
