<script setup lang="ts">
import { nextTick, onMounted, onBeforeUnmount, ref, toRaw, reactive, type Ref } from 'vue';
import axiosApiInstance from '@/helpers/api.axios.headers';
import FullscreenLoader from '@/components/animations/FullscreenLoader.vue';
import { useRoute } from 'vue-router';


interface Job {
    id: number;
    departure_country: string;
    departure_city: string;
    departure_airport: string;
    destination_country: string;
    destination_city: string;
    destination_airport: string;
    departure_date: Date;
    nights: number;
    occupancy: string;
    job_status: string;
}

type FiltersState = {
    [key: string]: string;
};

const items: Ref<Array<Job>> = ref([]);
const filteredItems: Ref<Array<Job>> = ref([]);
const fullscreenLoader = ref(false);
const route = useRoute();
const currentPage = ref(1);
const totalPages = ref(0);
const totalRecords	 = ref(0);
const isLoading = ref(false);

const depCountryFilter:Ref<Array<string>> = ref([]);
const depCityFilter:Ref<Array<string>> = ref([]);
const depAirportFilter:Ref<Array<string>> = ref([]);
const destCountryFilter:Ref<Array<string>> = ref([]);
const destCityFilter:Ref<Array<string>> = ref([]);
const destAirportFilter:Ref<Array<string>> = ref([]);
const depDateFilter:Ref<Array<string>> = ref([]);
const stayFilter:Ref<Array<string>> = ref([]);
const statusFilter:Ref<Array<string>> = ref([]);

onMounted(() => {
    getJobs(false);
    window.addEventListener('scroll', handleScroll); 
});

onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll); // Clean up event listener
});


const getJobs = (reset: boolean) => {

    if (isLoading.value || (totalPages.value && currentPage.value > totalPages.value)) {
        return;
    }
    isLoading.value = true;
    

    axiosApiInstance
        .post('/getCacheJobs', {
            page: currentPage.value, // Send the current page as a query parameter
            cache_identifier: route.params.id,
            filters: filters
        })
        .then(function (response) {
            if (!response.data.status) {
                alert(response.data.message);
            } else {

                if(!reset){
                    depCountryFilter.value = response.data.filters.departure_country;
                    depCityFilter.value = response.data.filters.departure_city;
                    depAirportFilter.value = response.data.filters.departure_airport;
                    destCountryFilter.value = response.data.filters.destination_country;
                    destCityFilter.value = response.data.filters.destination_city;
                    destAirportFilter.value = response.data.filters.destination_airport;
                    depDateFilter.value = response.data.filters.departure_date;
                    stayFilter.value = response.data.filters.nights;
                }else{
                    filteredItems.value = items.value = [];
                }


                filteredItems.value = items.value = [...items.value, ...response.data.jobs]; 
                totalPages.value = response.data.pagination.totalPages;
                totalRecords.value = response.data.pagination.totalRecords;
                currentPage.value += 1; 
                

            }
        })
        .catch(function (error) {
            alert(error);
        })
        .finally(() => {
            isLoading.value = false;
            fullscreenLoader.value = false;
        });
}



const initialFiltersState: FiltersState = {
    departure_country: 'all',
    departure_city: 'all',
    departure_airport: 'all',
    destination_country: 'all',
    destination_city: 'all',
    destination_airport: 'all',
    departure_date: 'all',
    nights: 'all',
    job_status: 'all',
};

const filters = reactive({
    ...initialFiltersState,
});

const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
        getJobs(false);
    }
};

const filterResults = (reset: boolean) => {

    fullscreenLoader.value = true;

    if (reset) {
        filters.departure_country = 'all';
        filters.departure_city = 'all';
        filters.departure_airport = 'all';
        filters.destination_country = 'all';
        filters.destination_city = 'all';
        filters.destination_airport = 'all';
        filters.departure_date = 'all';
        filters.nights = 'all';
        filters.job_status = 'all';
    }

    getJobs(!reset);

};

</script>
<template>
    <div>
        <FullscreenLoader v-show="fullscreenLoader" />
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div class="border-2 border-gray-400 rounded p-5">
                <div class="flex justify-between">
                    <div class="text-xl">{{ totalRecords }} Jobs</div>
                </div>
                <hr class="border-gray-400 my-2" />
                <div class="flex justify-between">
                    <div class="flex mr-2">
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="departureCountry" class="block text-gray-700">Departure country</label>
                            <select id="departureCountry" name="departureCountry" class="form-select w-52 mt-1" v-model="filters.departure_country" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="depCountry in depCountryFilter" :value="depCountry" :key="depCountry">
                                    {{ depCountry }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="departureCity" class="block text-gray-700">Departure city</label>
                            <select id="departureCity" name="departureCity" class="form-select w-52 mt-1" v-model="filters.departure_city" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="depCity in depCityFilter" :value="depCity" :key="depCity">
                                    {{ depCity }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="departureAirport" class="block text-gray-700">Departure airport</label>
                            <select id="departureAirport" name="departureAirport" class="form-select w-52 mt-1" v-model="filters.departure_airport" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="depAirport in depAirportFilter" :value="depAirport" :key="depAirport">
                                    {{ depAirport }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="arrivalCountry" class="block text-gray-700">Arrival country</label>
                            <select id="arrivalCountry" name="arrivalCountry" class="form-select w-52 mt-1" v-model="filters.destination_country" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="destCountry in destCountryFilter" :value="destCountry" :key="destCountry">
                                    {{ destCountry }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="arrivalCity" class="block text-gray-700">Arrival city</label>
                            <select id="arrivalCity" name="arrivalCity" class="form-select w-52 mt-1" v-model="filters.destination_city" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="destCity in destCityFilter" :value="destCity" :key="destCity">
                                    {{ destCity }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="arrivalAirport" class="block text-gray-700">Arrival airport</label>
                            <select id="arrivalAirport" name="arrivalAirport" class="form-select w-52 mt-1" v-model="filters.destination_airport" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="destAirport in destAirportFilter" :value="destAirport" :key="destAirport">
                                    {{ destAirport }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="depDate" class="block text-gray-700">Departure date</label>
                            <select id="depDate" name="depDate" class="form-select w-52 mt-1" v-model="filters.departure_date" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="depDate in depDateFilter" :value="depDate" :key="depDate">
                                    {{ depDate }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="stay" class="block text-gray-700">Stay</label>
                            <select id="stay" name="stay" class="form-select w-52 mt-1" v-model="filters.nights" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="stay in stayFilter" :value="stay" :key="stay">
                                    {{ stay }}
                                </option>
                            </select>
                        </div>
                        <div class="col-span-6 sm:col-span-3 mr-2">
                            <label for="status" class="block text-gray-700">Status</label>
                            <select id="status" name="status" class="form-select w-52 mt-1" v-model="filters.job_status" @change="currentPage = 1">
                                <option value="all">All</option>
                                <option v-for="status in statusFilter" :value="status" :key="status">
                                    {{ status }}
                                </option>
                            </select>
                        </div>

                    </div>
                    <div class="flex items-end">
                        <router-link 
                            :to="`/caching`" 
                            class="p-3 mr-2 inline-flex items-center font-medium text-blue-600 dark:text-blue-500 hover:underline">
                            ← Back
                        </router-link>
                        <button class="btn-success mr-2" @click="filterResults(false)">Filter</button>
                        <button class="btn-warning mr-2" @click="filterResults(true)">Reset</button>
                    </div>
                </div>
            </div>

            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" class="px-2 py-1">
                            #
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Departure country
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Departure city
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Departure airport
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Arrival country
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Arrival city
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Arrival airport
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Departure date
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Stay
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Occupancy
                        </th>
                        <th scope="col" class="px-2 py-1">
                            Status
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in filteredItems" :key="index"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td class="px-2 py-1">
                            {{ index + 1 }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.departure_country }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.departure_city }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.departure_airport }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.destination_country }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.destination_city }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.destination_airport }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.departure_date }}
                        </td>
                        <td class="px-2 py-1">
                            {{ item.nights }}
                        </td>
                        <td class="px-2 py-1">
                            <span v-if="JSON.parse(item.occupancy)[0].is_twin == 1">
                                Twin room
                            </span>
                            <span>
                                Adults: {{ JSON.parse(item.occupancy)[0].adults }}
                            </span>
                            <span v-if="JSON.parse(item.occupancy)[0].children > 0">
                                ,Children: {{ JSON.parse(item.occupancy)[0].children }}
                                ,Ages: {{ JSON.parse(item.occupancy)[0].ages }}
                            </span>
                        </td>
                        <td class="px-2 py-1">
                            <div :title="item.job_status" class="flex items-center">
                                <div v-if="item.job_status == 'finished'" class="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                                <div v-else-if="item.job_status == 'wip'" class="h-2.5 w-2.5 rounded-full bg-yellow-500 me-2"></div>
                                <div v-else-if="item.job_status == 'new'" class="h-2.5 w-2.5 rounded-full bg-blue-500 me-2"></div>
                                <div v-else class="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            
            <p v-if="isLoading">
                <div class="flex items-center my-3 justify-center border border-gray-200 rounded-lg bg-gray-50 dark:bg-gray-800 dark:border-gray-700">
                    <div class="px-6 py-2 text-xs font-medium leading-none text-center text-blue-800 bg-blue-200 rounded-full animate-pulse dark:bg-blue-900 dark:text-blue-200">Loading...</div>
                </div>
            </p>
            <p v-if="!isLoading && currentPage > totalPages">No more items to load.</p>
        </div>

    </div>
</template>